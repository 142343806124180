// import Header from './components/header/Header';
// import StarPreloader from './components/main/StarPreloader';
// import EndPreloader from './components/main/EndPreloader';
import Main from "./components/main/Main";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Courses from "./components/main/home/courses/Courses";
import MainMenu from "./components/header/MainMenu";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

import css from "./App.module.css"

// import css from './App.module.css';

function App() {
	return (
		<div className={css['grid']}>
			{/* <StarPreloader /> */}
			<BrowserRouter>
				<Header />

				<Routes>
					<Route path='/' element={<Main />} />
					<Route path='/courses' element={<Courses />} />
				</Routes>
				<Footer />
			</BrowserRouter>
			{/* <EndPreloader /> */}
			{/* <Header /> */}
		</div>
	);
}

export default App;
