import style from './TopBar.module.css'

function TopBar () {

    return (
        <div className={style.headerTop}>
                    <div className={style.headerContactInfo}>
                        <address>
                            <a href="mailto:pca@pc-acad.com">
                                pca@pc-acad.com
                            </a>
                            <a href="tel:+359886942644"> +359 886 942 644 </a>
                            <a href="tel:+359898304029"> +359 898 304 029 </a>
                        </address>
                    </div>
                </div>
    )
}

export default TopBar