import css from "./CourseCard.module.css";

export default function CourseCard({ data }) {
	return (
		<div key={data.key} className={css["course-card"]}>
			<div className={css["course-thumbnail"]}>
				{/* <a href="#"> */}
				<img src={data.image} className={css["img-fluid"]} alt={String(data.image)} />
				{/* </a> */}
			</div>
			<div className={css["course-content"]}>
				{/* <span className={css["course-price"]}>$15.00</span> */}
				<h3 className={css["course-title"]}>
					<p className={css["center"]}>{data.title}</p>
				</h3>

				<div className='course-content-footer'>
					<ul className={css["none"]}>
						<li>
							<i className='fa fa-play'></i> <span className={css["heavy"]}>Duration:</span> {data.duration}{" "}
						</li>
						<li>
							<i className='fa fa-calendar'></i> <span className={css["heavy"]}>Date:</span> {data.date}{" "}
						</li>
						<li>
							<i className='fa fa-clock-o'></i> <span className={css["heavy"]}>Time:</span> {data.time}{" "}
						</li>
						<li>
							<i className='fa fa-map-pin'></i> <span className={css["heavy"]}>Location:</span> {data.location}{" "}
						</li>
						<li>
							<i className='fa fa-tag'></i> <span className={css["heavy"]}>Price:</span> {data.price}{" "}
						</li>
						<li>
							<i className='fa fa-wrench'></i> <span className={css["heavy"]}>Practical:</span> {data.practical}{" "}
						</li>
					</ul>
				</div>
			</div>
			<div className={css["button-right"]}>
				<a className={css["form-button"]} href={data.form} target='_blank' rel='noreferrer'>
					Request form
				</a>
			</div>
		</div>
	);
}
