import style from './Logo.module.css'

function Logo () {

    return (
        <div className={style.siteLogo}>
            <a href="/" className={style.logo}>
                <img src="/logo.png" alt="Plane Care Academy Logo" />
            </a>
        </div>
    )
}

export default Logo