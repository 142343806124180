import { Link, NavLink } from "react-router-dom";
import css from "./MainMenu.module.css";
import TopBar from "./TopBar";
import Logo from "../common/Logo";

export default function MainMenu() {

    

	return (
		<div className={css.mainMenuArea}>
			<nav className={css.mainMenu}>
				<ul>
					<li>
						<NavLink to='/' className={({ isActive }) => (isActive ? css.active : css.inactive)}>Home</NavLink>
					</li>
					<li>
						<NavLink to='/courses' className={({ isActive }) => (isActive ? css.active : css.inactive)}>Courses</NavLink>
					</li>
					{/* <li>
						<a href='/news'>News</a>
					</li>
					<li>
						<a href='/certification'>Certification</a>
					</li>
					<li>
						<a href='/contacts'>Contacts</a>
					</li> */}
				</ul>
			</nav>
		</div>
	);
}
