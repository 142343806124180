import Header from "../header/Header";
import Home from "./home/Home";
import Footer from "../footer/Footer";

import css from "./Main.module.css";
import Upcoming from "./home/Upcoming";

function Main() {
	return (
		<div className={css.main}>
			{/* <Header /> */}
			<main>
				<Home />
				
			</main>
			
		</div>
	);
}

export default Main;
