import { Link } from "react-router-dom";

import css from './Courses.module.css'

export default function Courses () {

    return (
        <section className={css["hero-area"]}>
            <div className={css["container"]}>
                <div
                    className={`${css["caption-content"]} ${css["text-center"]}`}
                >
                    <h4>Courses</h4>
                    <h2>Ooops! This section is under construction ...</h2>
                    
                </div>
            </div>
        </section>
    )
}