import Logo from "../common/Logo";
import MainMenu from "./MainMenu";
import TopBar from "./TopBar";
import LogReg from "./LogReg";

import css from "./Header.module.css";

export default function Header() {
    return (
        <header className={css.header}>
            <TopBar />
            <div className={css.headerBottom}>
                <div className={css.container}>
                    <div className={css.mainMenuWrap}>
                        <Logo />
                        <MainMenu />
                        {/* <LogReg /> */}
                    </div>
                </div>
            </div>
        </header>
    );
}
