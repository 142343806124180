import css from "./Home.module.css";
import Upcoming from "./Upcoming";

export default function Home() {
	return (
		<>
			<section className={css.heroArea}>
				<div className={css.container}>
					<div className={`${css.captionContent} ${css.textCenter}`}>
						<h4>The next level of training!</h4>
						<h2>Plane Care Academy</h2>
						<p>
							Our goal is to train aviation professionals who will shape the future of the aircraft maintenance business. As a side effect, you
							will be able to certify yourself as per EASA Part-66 requirements.
						</p>
					</div>
				</div>
			</section>
			<Upcoming />
		</>
	);
}
