// import css from "./Footer.module.css";
import Logo from "../common/Logo";
import css from "./Footer.module.css";

export default function Footer() {
	return (
		<footer className={css["footer-area"]}>
			{/* <!--start footer top area--> */}
					<div className={css["hidden"]}>
			<div className={css["footer-top-area"]}>
				<div className={css["container"]}>
						<div className={css["grid"]}>
							{/* <!--start footer widget--> */}
							<div className={`${css["col-lg-3"]} ${css["col-md-6"]}`}>
								<div className={`${css["footer-widget"]} ${css["logo"]}`}>
									<Logo />
									<div className={css["footer-about-description"]}>
										<p className={css["center"]}>We are EASA Part-147 Aircraft Maintenance Training Organisation.</p>
									</div>
									<h6>Follow Us</h6>
									<ul className={css["footer-social-icons"]}>
										<li>
											<a href='linkedin.com'>
												<i className={`${css["fa"]} ${css["fa-linkedin"]}`}></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
							{/* <!--end footer widget--> */}
							<div></div>
							{/* <!--start footer widget--> */}
							<div>
								<div className={`${css["footer-widget"]} ${css["footer-contact"]}`}>
									<h4>Contact Us</h4>
									<ul className={css["ul-none"]}>
										<li>
											<i className='fa fa-map-marker'></i>
											<p className={css["lines"]}>Sofia Airport, block 3, office 1, Sofia, Bulgaria</p>
										</li>
										<li>
											<i className='fa fa-envelope' aria-hidden='true'></i>
											<p className={css["lines"]}>pca@pc-acad.com</p>
										</li>
										<li>
											<i className='fa fa-phone' aria-hidden='true'></i>
											<p className={css["lines"]}>+359 898 304 029</p>
										</li>
									</ul>
								</div>
							</div>
							{/* <!--end footer widget--> */}
						</div>
					</div>
				</div>
			</div>
			{/* <!--end footer top area--> */}
			{/* <!--start footer bottom--> */}
			<div className={css["footer-bottom-area"]}>
				<div className={css["container"]}>
					<p className={css["lines"]}>&copy; Copy 2023 | PCA | All Rights Reserved</p>
				</div>
			</div>
			{/* <!--end footer bottom--> */}
		</footer>
	);
}
